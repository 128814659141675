import logo from './logo.svg';
import './App.css';

/*
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBRcd0k6IXwRvIWgQlOBYf6O6UurnTAUTI",
  authDomain: "debuggin-website.firebaseapp.com",
  projectId: "debuggin-website",
  storageBucket: "debuggin-website.appspot.com",
  messagingSenderId: "391757329664",
  appId: "1:391757329664:web:3f7a4ea9c3383a7c7cafe5"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
*/
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Coming Soon!
        </p>
      </header>
    </div>
  );
}

export default App;
